@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:wght@700&family=Poppins&display=swap");

:root {
  --view-unit: 1vw;
  $primary-color: #2e424f !global;
  $secondary-color: #40373f !global;
  $text-color: white !global;
  $primary-font: "Playfair Display", serif !global;
  $secondary-font: "Poppins", sans-serif !global;
}
body {
  background-color: $secondary-color;
  visibility: hidden;
}

.blueCol {
  background-color: $primary-color;
}

.slide {
  height: 100vh;
  display: flex;
  flex-flow: column;
  justify-content: center;
  font-family: $secondary-font;
}

.nav-btn {
  text-align: left;
  text-decoration: none;
  color: $text-color;
  font-size: 2.16vw;
  font-family: $primary-font;
  font-weight: 700;
  padding: 0;
}

.nav-btn:focus {
  box-shadow: none;
}

.nav-btn:hover {
  color: $text-color;
}

.gmap-btn {
  margin-top: 1vw;
}

/* google maps iframe */

.gmap_canvas {
  overflow: hidden;
  height: 30vh !important;
  width: 100%;
}

#gmap_canvas {
  height: 30vh;
  width: 100%;
}

/* desktop */

@media only screen and (min-width: 992px) {
  body {
    overflow: hidden;
  }

  .blueCol,
  .brownCol,
  .image {
    height: 100vh;
  }

  /* navigation */

  .blueCol {
    padding: 5.5% 0 0 7.5%;
  }

  .nav-btn {
    opacity: 40%;
    height: 4.5vw;
  }

  [data-index="0"] {
    opacity: 100%;
    font-size: 2.3vw;
  }

  .logo {
    margin-bottom: 14vh;
  }

  /* content */

  .slide {
    opacity: 40%;
  }

  .brownCol {
    overflow: hidden;
    color: $text-color;
  }

  [data-slide="0"] {
    padding-top: 8.5vw;
  }

  [data-slide="1"] {
    padding-top: 9.6vw;
  }

  [data-slide="2"] {
    padding-top: 15vw;
  }

  [data-slide="3"] {
    padding-top: 8.5vw;
  }

  [data-slide="4"] {
    padding-top: 4.25vw;
  }

  .content-col {
    padding: 0 2vw;
    position: relative;
    font-size: 1.3vw;
  }

  /* image */

  .image {
    background-image: url("images/cesky-krumlov.png");
    background-size: cover;
  }
}

/* mobile version */

@media only screen and (max-width: 991.8px) {
  * {
    overflow-x: hidden;
  }

  body {
    font-size: calc(5 * var(--view-unit));
    background-color: $primary-color;
  }

  .col-lg-7 {
    padding: 0;
  }

  .mobile-nav {
    margin-top: calc(4 * var(--view-unit));
    background-color: $primary-color;
  }

  .nav-container {
    padding: 0;
    margin: 0;
  }

  .navbar-brand {
    margin: 0;
  }

  .navbar-expand .navbar-toggler {
    display: flex;
  }

  .navbar-toggler:focus {
    box-shadow: none;
  }

  .navbar-dark .navbar-toggler {
    border: none;
  }

  .navbar-toggler {
    padding: 0;
  }

  .navbar-toggler-icon {
    color: $text-color;
    width: calc(13 * var(--view-unit));
    height: calc(13 * var(--view-unit));
  }

  .navbar-collapse {
    margin-top: calc(4 * var(--view-unit));
  }

  .nav-link {
    color: $text-color;
    font-size: 3vh;
    padding: calc(3 * var(--view-unit)) 0;
    font-family: $secondary-font;
  }
  .nav-link:focus,
  .nav-link:hover {
    color: $text-color;
    opacity: 60%;
  }

  .arrow {
    width: calc(8 * var(--view-unit));
    height: calc(8 * var(--view-unit));
    position: absolute;
    top: 90vh;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    text-align: center;
  }

  .logo {
    width: calc(40 * var(--view-unit));
  }

  .col-lg-6 {
    overflow-y: hidden;
  }

  .brownCol {
    background-color: $secondary-color;
  }

  .slide {
    padding: 0;
  }

  .first-slide {
    padding-bottom: calc(34 * var(--view-unit));
    font-size: calc(7 * var(--view-unit));
  }

  .up-arrow-div {
    width: calc(12 * var(--view-unit));
    height: calc(12 * var(--view-unit));
    border-radius: 100%;
    background-color: rgba(0, 0, 0, 0.3);
    position: fixed;
    top: 85vh;
    left: 84vw;
    transform: translate(-50%, -50%);
    visibility: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .up-arrow {
    height: calc(7 * var(--view-unit));
  }

  .gmap-btn {
    margin-top: calc(3 * var(--view-unit));
    font-size: 2.5vh;
  }

  .nav-btn {
    font-size: calc(9 * var(--view-unit));
    margin-bottom: calc(9 * var(--view-unit));
    line-height: 1.2;
  }

  .mobile-ceny-sluzeb {
    line-height: 1.3;
  }

  [data-index="3"] {
    line-height: 1.5;
  }

  ul {
    padding-left: calc(4 * var(--view-unit));
  }
  li {
    overflow-x: visible;
  }

  .blueCol,
  .brownCol {
    color: $text-color;
    padding: 0 calc(12 * var(--view-unit));
    height: 100vh;
  }
}

// fixing nav burger menu responsive position

@media (min-width: 768px) {
  .container {
    max-width: none;
  }
}

@media (min-width: 576px) {
  .container {
    max-width: none;
  }
}
